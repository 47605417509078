import { render, staticRenderFns } from "./Newsletter.vue?vue&type=template&id=669b8e7e&"
import script from "./Newsletter.vue?vue&type=script&lang=js&"
export * from "./Newsletter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RgInputTextField: require('/builds/reifen_gundlach/frontend/components/forms/RgInputTextField/RgInputTextField.vue').default,Button: require('/builds/reifen_gundlach/frontend/components/atoms/Button/Button.vue').default})
