//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed } from '@nuxtjs/composition-api'
import useCustomerData from '~/composables/useCustomerData'
import useLoginInformation from '~/composables/useLoginInformation'
import useNewsletter from '~/composables/useNewsletter'
import { useNotifications } from '~/composables/useNotifications'
import useFaroIntegration from '~/composables/useFaroIntegration'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  name: 'Newsletter',
  props: {
    headline: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { customerData } = useCustomerData()
    const { userIsLoggedIn, userIsAuthenticated } = useLoginInformation()
    const { addNotification } = useNotifications()
    const { newsletterStatus, submitNewsletterForUser, submitNewsletterForGuest } = useNewsletter()
    const { faroError } = useFaroIntegration()
    const { currentLanguage } = useCurrentLanguage()

    const isInactive = ref(false)
    const isLoading = ref(false)

    const hintWithUnderline = computed(() => {
      return props.hint.replace('<a', '<a class="underline"')
    })

    async function submitNewsletter () {
      isLoading.value = true
      if (userIsAuthenticated.value) {
        try {
          await submitNewsletterForUser()
          addNotification('noti-newsletter-submit')
          isInactive.value = true
        } catch (error) {
          faroError(error, 'newsletter-subscribe')
          addNotification('', error)
        }
      } else {
        try {
          await submitNewsletterForGuest({
            email: customerData.value.email,
            SessionID: new Date().getTime(),
            PreferredLanguage: currentLanguage.value
          })
          addNotification('noti-newsletter-submit')
          isInactive.value = true
        } catch (error) {
          faroError(error, 'newsletter-guest-subscribe')
          addNotification('', error)
        }
      }
      isLoading.value = false
    }

    return {
      customerData,
      userIsLoggedIn,
      newsletterStatus,
      isInactive,
      isLoading,
      hintWithUnderline,
      submitNewsletter
    }
  }
})
