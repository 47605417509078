//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, useContext, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'RgInputTextField',
  props: {
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    hint: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup (props) {
    const { $t } = useContext()
    const inputValue = ref(props.value)

    watch(() => props.value, () => {
      inputValue.value = props.value
    })

    const effectiveLabel = computed(() => {
      return `${props.label}${props.required ? '' : ` (${$t('forms.optional')})`}`
    })

    const effectiveRules = computed(() => {
      const rules = {
        ...props.rules
      }

      rules.required = props.required

      return rules
    })

    return {
      inputValue,
      effectiveRules,
      effectiveLabel
    }
  }
})
