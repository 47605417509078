//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'RgInputText',
  props: {
    icon: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    hint: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: null
    },
    alignment: {
      type: String,
      default: 'left'
    },
    isInputButtonCombination: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const focus = ref(false)
    const inputValue = ref(props.value)

    watch(() => props.value, () => {
      inputValue.value = props.value
    })

    const showLabelTop = computed(() => {
      return (inputValue.value || focus.value || (props.type !== 'text' && props.type !== 'tel' && props.type !== 'email'))
    })

    return {
      inputValue,
      showLabelTop,
      focus
    }
  }
})
