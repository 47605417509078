import axios from 'axios'
import { ref, onMounted, watchEffect } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'
import useFaroIntegration from '~/composables/useFaroIntegration'

const newsletterStatus = ref(false)

const useNewsletter = () => {
  const { userIsAuthenticated } = useLoginInformation()
  const { faroError } = useFaroIntegration()

  async function getNewsletterStatus () {
    try {
      const response = await axios.get('/proxy/newsletter/customer/subscriptions')
      newsletterStatus.value = response.data.isSubscribed
    } catch (error) {
      faroError(error, 'newsletter-getStatus')
    }
  }

  function submitNewsletterForUser () {
    return axios.put('/proxy/newsletter/customer/subscribe')
  }

  function submitNewsletterForGuest (formData) {
    return axios.post('/api/v1/newsletter/submitGuest', formData)
  }

  function cancelNewsletterForUser () {
    return axios.put('/proxy/newsletter/customer/unsubscribe')
  }

  onMounted(() => {
    if (userIsAuthenticated.value) {
      getNewsletterStatus()
    } else {
      const unwatch = watchEffect(() => {
        if (userIsAuthenticated.value) {
          getNewsletterStatus()
          unwatch()
        }
      })
    }
  })

  return {
    newsletterStatus,
    submitNewsletterForUser,
    submitNewsletterForGuest,
    cancelNewsletterForUser,
    getNewsletterStatus
  }
}

export default useNewsletter
