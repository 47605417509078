var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.icon)?_c('Icon',{staticClass:"icon",class:{
      'label--error': _vm.error
    },attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),(_vm.label)?_c('label',{staticClass:"label",class:[
      _vm.showLabelTop ? 'label--filled' : 'label--empty',
      {
        'label--error': _vm.error,
        'label--disabled': _vm.disabled,
        'label--empty-icon': _vm.icon && !_vm.showLabelTop
      }
    ],attrs:{"for":_vm.id}},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[
      _vm.isInputButtonCombination ? 'px-4 style-border-orange rounded-l' : 'px-6 style-border-blue rounded',
      {
        'text-right': _vm.alignment === 'right',
        'input--error': _vm.error,
        'input--icon': _vm.icon,
      }],attrs:{"id":_vm.id,"required":_vm.required,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false; _vm.$emit('blur')},"input":function($event){return _vm.$emit('input', _vm.inputValue)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[
      _vm.isInputButtonCombination ? 'px-4 style-border-orange rounded-l' : 'px-6 style-border-blue rounded',
      {
        'text-right': _vm.alignment === 'right',
        'input--error': _vm.error,
        'input--icon': _vm.icon,
      }],attrs:{"id":_vm.id,"required":_vm.required,"disabled":_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false; _vm.$emit('blur')},"input":function($event){return _vm.$emit('input', _vm.inputValue)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],staticClass:"input",class:[
      _vm.isInputButtonCombination ? 'px-4 style-border-orange rounded-l' : 'px-6 style-border-blue rounded',
      {
        'text-right': _vm.alignment === 'right',
        'input--error': _vm.error,
        'input--icon': _vm.icon,
      }],attrs:{"id":_vm.id,"required":_vm.required,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false; _vm.$emit('blur')},"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},function($event){return _vm.$emit('input', _vm.inputValue)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')}}}),_vm._v(" "),(_vm.hint || _vm.$slots.hint)?_c('div',{staticClass:"ml-6 text-200 text-grey hint-text"},[(_vm.hint)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.hint)}}):_vm._e(),_vm._v(" "),_vm._t("hint")],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }